import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Paper wrap=flex",
        "componentName": "Paper",
        "wrap": "flex"
      }}>{`<Paper hoverable p={4}>
  Faster than my shadow
</Paper>
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Paper" mdxType="Props" />
    <h2 {...{
      "id": "elevation",
      "style": {
        "position": "relative"
      }
    }}>{`Elevation`}</h2>
    <p><inlineCode parentName="p">{`default: 1`}</inlineCode></p>
    <p>{`To increase the paper's relative depth, you can alter the `}<inlineCode parentName="p">{`elevation`}</inlineCode>{` prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Paper elevation={-1} p={4}>Elevation 1</Paper>
<Paper elevation={0} p={4}>Elevation 0</Paper>
<Paper elevation={1} p={4}>Elevation 1</Paper>
<Paper elevation={2} p={4}>Elevation 2</Paper>
<Paper elevation={3} p={4}>Elevation 2</Paper>
`}</code></pre>
    <h2 {...{
      "id": "hoverable",
      "style": {
        "position": "relative"
      }
    }}>{`Hoverable`}</h2>
    <p><inlineCode parentName="p">{`default: false`}</inlineCode></p>
    <p>{`To indicate to users that a paper has an action or a link bound to it, you can
add a `}<inlineCode parentName="p">{`hoverable`}</inlineCode>{` prop. This will increase the paper's depth when a user mouses
over it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Paper hoverable={false}  p={4}>This paper is not hoverable</Paper>
<Paper hoverable={true}  p={4}>This paper is hoverable</Paper>
`}</code></pre>
    <h2 {...{
      "id": "related-components",
      "style": {
        "position": "relative"
      }
    }}>{`Related components`}</h2>
    <ul>
      <li parentName="ul">{`Paper extends the `}<a parentName="li" {...{
          "href": "components/box/code/"
        }}>{`Box`}</a>{` component, this means every
functionality available on Box is applicable on Paper as well.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      